import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  CardTitle,
  Button,
  Icon
} from "reactstrap";

const Home = props => {
  return (
    <Container>
      <Row>
        <Col sm={12} md={4}>
          &nbsp;
        </Col>
        <Col sm={12} md={4}>
          <img
            src="/images/logo.png"
            className="img-fluid"
            alt="Subjective Wine Logo"
          />
        </Col>
        <Col sm={12} md={4}>
          &nbsp;
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4}>
          <Card color="primary" outline className="">
            <CardHeader className="bg-primary text-light">
              Artifcial Intelligence Engine
            </CardHeader>
            <CardBody>
              Our AI engine learns the wine you like, and helps you choose the
              right bottle for you!
            </CardBody>
            <CardFooter>
              <CardImg
                src="/images/page/home-main.svg"
                alt="Subjective Wine AI"
                height="193.41"
              ></CardImg>
            </CardFooter>
          </Card>
        </Col>
        <Col className="d-sm-block d-md-none">&nbsp;</Col>
        <Col sm={12} md={4}>
          <Card color="primary" outline className="">
            <CardHeader className="bg-primary text-light">
              Augmented Reality (AR) Mobile App
            </CardHeader>
            <CardBody>
              Our mobile app will empower you to scan wine bottles at your local
              store, and choose the right wine for you based on the occasion.
            </CardBody>
            <CardFooter>
              <CardImg
                src="/images/page/home-ar.svg"
                alt="Subjective Wine Augmented Reality"
                height="193.41"
              ></CardImg>
            </CardFooter>
          </Card>
        </Col>
        <Col className="d-sm-block d-md-none">&nbsp;</Col>
        <Col sm={12} md={4}>
          <Card color="primary" outline className="">
            <CardHeader className="bg-primary text-light">
              Perfect Bottle
            </CardHeader>
            <CardBody>
              Enjoy the perfect bottle with friends and family!
            </CardBody>
            <CardFooter>
              <CardImg
                src="/images/page/home-fun.svg"
                alt="Subjective Wine - Wine match"
              ></CardImg>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row className="d-sm-block d-md-none pad-btm">
        <Col sm={12} md={4}>
          &nbsp;
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
