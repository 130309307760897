import React from "react";
import { Container, Row, Col, Jumbotron, Button } from "reactstrap";

const NotFound = props => {
  return (
    <Container>
      <Row>
        <Col sm={12} md={2}>
          &nbsp;
        </Col>
        <Col sm={12} md={8}>
          <h4 className="display-4 text-secondary text-center">
            Page Not Found
          </h4>
          <img
            src="/images/page/404.svg"
            className="img-fluid"
            alt="404 - Not Found"
          />
        </Col>
        <Col sm={12} md={2}>
          &nbsp;
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
