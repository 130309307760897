import React from "react";
import { Container, Row, Col } from "reactstrap";

import SocialLink from "./SocialLink";

import {
  faPinterest,
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";

const Footer = _props => {
  return (
    <footer className="footer">
      <Container fluid>
        <Row>
          <Col sm={12}>
            <p className="text-primary text-center">
              &copy; Copyright 2020 - Subjective Wine, All rights reserved
            </p>
          </Col>
          {/* <Col sm={12} md={6}>
            <span className="social-buttons float-right">
              <SocialLink icon={faPinterest} href="https://pinterest.com" />
              <SocialLink icon={faFacebook} href="https://facebook.com/" />
              <SocialLink icon={faInstagram} href="https://instagram.com/" />
              <SocialLink icon={faTwitter} href="https://twitter.com/" />
              <SocialLink
                icon={faLinkedinIn}
                href="https://linkedin.com/company/"
              />
            </span>
          </Col> */}
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
