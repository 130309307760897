import React from "react";

import { BrowserRouter, Link, Route, Switch } from "react-router-dom";

import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound";

import SiteNavigation from "./Components/SiteNavigation";
import Footer from "./Components/Footer";

const App = props => {
  return (
    <BrowserRouter>
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route component={NotFound} />
        </Switch>
      </div>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
